<template>
  <section class="section" id="talkshow-youtube">
    <div class="container-fluid">
      <div class="card">
        <b-card-header header-tag="nav">
          <b-nav card-header tabs>
            <b-nav-item active v-b-toggle.talkshowYoutube variant="primary">
              TalkShow Youtube
            </b-nav-item>
          </b-nav>
        </b-card-header>
        <b-collapse
          id="talkshowYoutube"
          visible
          class="mt-2 show mb-4 ml-4 mr-4"
        >
          <div class="row mt-3">
            <button
              class="btn btn-primary edit-button"
              squared
              v-if="!isEditing"
              @click="onEdit"
            >
              Edit
            </button>
          </div>
          <div class="row">
            <template v-for="(item, index) of links">
              <div class="col-lg-12 mb-3" :key="index">
                <div class="row">
                  <b-col sm="3">
                    <label :for="`${item.key}`" class="label-content">{{
                      renderKey(item.key)
                    }}</label>
                  </b-col>
                  <b-col sm="9">
                    <b-form-input
                      :id="item.key"
                      v-model="item.link"
                      :disabled="!isEditing"
                    ></b-form-input>
                  </b-col>
                </div>
              </div>
            </template>
          </div>
          <div class="row d-flex justify-content-center" v-if="isEditing">
            <b-button
              class="btn btn-primary save-button mr-2"
              squared
              @click="onSave"
            >
              Save
            </b-button>
            <b-button
              squared
              @click="onCancel"
              class="btn btn-primary cancel-button"
            >
              Cancel
            </b-button>
          </div>
        </b-collapse>
      </div>
    </div>
  </section>
</template>

<script>
import { translationType } from "@/constant/index.js";
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      isEditing: false,
      links: [],
    };
  },
  computed: {
    ...mapGetters("youtube", ["talkshowYoutube"]),
  },
  watch: {
    talkshowYoutube: {
      handler(val) {
        if (val && val.length) {
          this.links = [...val];
        }
      },
      immediate: true,
    },
  },
  async mounted() {
    try {
      await this.getTalkshowYoutube(translationType.talkshow);
    } catch (err) {}
  },
  methods: {
    ...mapActions("youtube", ["getTalkshowYoutube", "updateTalkShow"]),
    onEdit() {
      this.isEditing = true;
    },
    renderKey(key) {
      let res = "";
      if (key) {
        res = key.replace(/_/g, " ");
        res = res.charAt(0).toUpperCase() + res.slice(1);
      }
      return res;
    },
    async onSave() {
      try {
        this.isEditing = false;
        const args = this.links.map((i) => {
          return {
            key: i.key,
            link: i.link,
          };
        });
        await this.updateTalkShow({
          youtubes: args,
          type: translationType.talkshow,
        });
      } catch (err) {}
    },
    onCancel() {
      this.isEditing = false;
      this.links = this.talkshowYoutube.map((i) => i);
    },
  },
};
</script>
<style lang="scss" scoped>
#talkshow-youtube {
  margin-bottom: 25px !important;
  margin-top: 0px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.label-content {
  word-break: break-word !important;
}

.edit-button,
.save-button {
  border: none !important;
  margin-left: 15px;
  margin-bottom: 15px;
  background-color: #00aeef !important;
}
.cancel-button {
  margin-left: 15px;
  margin-bottom: 15px;
}
.edit-button:hover,
.save-button:hover {
  background-color: #00aeef !important;
}
</style>