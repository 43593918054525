<template>
  <section class="" id="admin-introduce-translation">
    <div class="container-fluid">
      <IntroduceTranslation
        :introductions="introTranslations"
        :language="currentLang"
      ></IntroduceTranslation>
      <DiscussionTranslation
        :discussions="discussTranslations"
        :language="currentLang"
      ></DiscussionTranslation>
      <ContactTranslation
        :contacts="contactTranslations"
        :language="currentLang"
      ></ContactTranslation>
      <TopicTranslation
        :topic="topic1Translation"
        :language="currentLang"
      ></TopicTranslation>
      <TopicTranslation2
        :topic="topic2Translation"
        :language="currentLang"
      ></TopicTranslation2>
      <TopicTranslation3
        :topic="topic3Translation"
        :language="currentLang"
      ></TopicTranslation3>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { translations, translationType } from "@/constant/index.js";
import IntroduceTranslation from "@/components/admin/IntroduceTranslation";
import DiscussionTranslation from "@/components/admin/DiscussionTranslation";
import ContactTranslation from "@/components/admin/ContactTranslation";
import TopicTranslation from "@/components/admin/TopicTranslation";
import TopicTranslation2 from "@/components/admin/TopicTranslation2";
import TopicTranslation3 from "@/components/admin/TopicTranslation3";

export default {
  components: {
    IntroduceTranslation,
    DiscussionTranslation,
    ContactTranslation,
    TopicTranslation,
    TopicTranslation2,
    TopicTranslation3,
  },
  data() {
    return {
      isTranslating: false,
      currentLang: "vn",
    };
  },
  computed: {
    ...mapGetters("translations", [
      "introTranslations",
      "discussTranslations",
      "contactTranslations",
      "topic1Translation",
      "topic2Translation",
      "topic3Translation",
    ]),
  },
  async mounted() {
    await this.getTranslation();
  },
  methods: {
    ...mapActions("translations", [
      "getTranslationByType",
      "getTranslationsByLanguage",
    ]),
    async getTranslation() {
      try {
        await this.getTranslationsByLanguage(this.currentLang);
      } catch (err) {}
    },
  },
};
</script>
<style lang="scss" scoped>
#admin-introduce-translation {
  padding-bottom: 25px !important;
  padding-top: 0px !important;
}
</style>
