<template>
  <div class="card" id="discuss-translation">
    <b-card-header header-tag="nav">
      <b-nav card-header tabs>
        <b-nav-item active v-b-toggle.discussTranslation variant="primary">
          Discussion Section</b-nav-item
        >
      </b-nav>
    </b-card-header>
    <b-collapse
      id="discussTranslation"
      visible
      class="mt-2 show mb-4 ml-4 mr-4"
    >
      <div class="row mt-3">
        <button
          class="btn btn-primary edit-button"
          squared
          v-if="!isEditing"
          @click="onEdit"
        >
          Edit
        </button>
      </div>
      <!-- Title -->
      <div class="row mb-3">
        <b-col sm="3">
          <label class="label-content" :for="`${discussionTitle.key}`">{{
            renderKey(discussionTitle.key)
          }}</label>
        </b-col>
        <b-col sm="9">
          <b-form-input
            :disabled="!isEditing"
            :id="discussionTitle.key"
            v-model="discussionTitle.value"
          ></b-form-input>
        </b-col>
      </div>
      <!-- Sub Title -->
      <div class="row mb-3">
        <b-col sm="3">
          <label class="label-content" :for="`${discussionSubtitle.key}`">{{
            renderKey(discussionSubtitle.key)
          }}</label>
        </b-col>
        <b-col sm="9">
          <b-form-input
            :id="discussionSubtitle.key"
            v-model="discussionSubtitle.value"
            :disabled="!isEditing"
          ></b-form-input>
        </b-col>
      </div>
      <!-- Talk show -->
      <div class="row">
        <template v-for="(item, index) of discussionSlideshow">
          <div class="col-lg-12 mb-3" :key="index">
            <div class="row" :key="index">
              <b-col sm="3">
                <label class="label-content" :for="`${item.key}`">{{
                  renderKey(item.key)
                }}</label>
              </b-col>
              <b-col sm="9">
                <b-form-input
                  :disabled="!isEditing"
                  :id="item.key"
                  v-model="item.value"
                ></b-form-input>
              </b-col>

              <div class="hr-div" />
            </div>
          </div>
        </template>
      </div>
      <!-- buttons -->
      <div class="row d-flex justify-content-center" v-if="isEditing">
        <b-button
          class="btn btn-primary save-button mr-2"
          squared
          @click="onSave"
        >
          Save
        </b-button>
        <b-button
          squared
          @click="onCancel"
          class="btn btn-primary cancel-button"
        >
          Cancel
        </b-button>
      </div>
    </b-collapse>
  </div>
</template>
<script>
import { translationKeys, translationType } from "@/constant/index.js";
import { mapActions } from "vuex";

export default {
  props: {
    discussions: {
      type: Array,
      default: () => [],
    },
    language: {
      type: String,
      default: "vn",
    },
  },
  data() {
    return {
      discussionTitle: {},
      discussionSubtitle: {},
      discussionSlideshow: [],
      discussionKeys: translationKeys.discussion,
      defaultKeys: [
        translationKeys.discussion.discussion_title,
        translationKeys.discussion.discussion_subtitle,
      ],
      isEditing: false,
    };
  },
  watch: {
    discussions: {
      handler(val) {
        if (val && val.length) {
          this.convertData(val);
        }
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions("translations", ["updateTranslation"]),
    renderKey(key) {
      let res = "";
      if (key) {
        res = key.replace(/_/g, " ");
        res = res.charAt(0).toUpperCase() + res.slice(1);
      }
      return res;
    },
    onEdit() {
      this.isEditing = true;
    },
    onCancel() {
      this.isEditing = false;
      this.convertData(this.discussions);
    },
    async onSave() {
      try {
        let payload = {
          discussion_title: this.discussionTitle.value,
          discussion_subtitle: this.discussionSubtitle.value,
        };
        const args = {};
        for (const item of this.discussionSlideshow) {
          args[item.key] = item.value;
        }
        payload = { ...payload, ...args };

        await this.updateTranslation({
          payload,
          language: this.language,
          type: translationType.discussion,
        });
        this.isEditing = false;
      } catch (err) {}
    },
    convertData(val) {
      this.discussionTitle = val.find(
        (i) => i.key == this.discussionKeys.discussion_title
      );
      this.discussionSubtitle = val.find(
        (i) => i.key == this.discussionKeys.discussion_subtitle
      );
      this.discussionSlideshow = val.filter((i) => {
        return !this.defaultKeys.includes(i.key);
      });
    },
  },
};
</script>
<style lang="scss">
#discuss-translation {
  margin-bottom: 25px !important;
  margin-top: 0px !important;
  .edit-button:hover,
  .save-button:hover {
    background-color: #00aeef !important;
  }

  .edit-button,
  .save-button {
    border: none !important;
    margin-left: 15px;
    margin-bottom: 15px;
    background-color: #00aeef !important;
  }
  .cancel-button {
    margin-left: 15px;
    margin-bottom: 15px;
  }
}

.label-content {
  word-break: break-word !important;
}
</style>