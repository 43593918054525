import { render, staticRenderFns } from "./ContactTranslation.vue?vue&type=template&id=06d1400c&scoped=true&"
import script from "./ContactTranslation.vue?vue&type=script&lang=js&"
export * from "./ContactTranslation.vue?vue&type=script&lang=js&"
import style0 from "./ContactTranslation.vue?vue&type=style&index=0&id=06d1400c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06d1400c",
  null
  
)

export default component.exports