<template>
  <div class="card" id="contact-translation">
    <b-card-header header-tag="nav">
      <b-nav card-header tabs>
        <b-nav-item active v-b-toggle.contactTranslation variant="primary">
          Footer and Contact Info</b-nav-item
        >
      </b-nav>
    </b-card-header>

    <b-collapse
      id="contactTranslation"
      visible
      class="mt-2 show mb-4 ml-4 mr-4"
    >
      <div class="row mt-3">
        <button
          class="btn btn-primary edit-button ml-2"
          squared
          v-if="!isEditing"
          @click="onEdit"
        >
          Edit
        </button>
        <button
          class="btn btn-primary edit-button"
          squared
          v-if="!isEditing"
          @click.prevent="onDownload"
        >
          Download Contact Submission
        </button>
      </div>
      <div class="row">
        <div class="col-lg-12 mb-3">
          <div class="row">
            <b-col sm="3">
              <label class="label-content" :for="`${title.key}`">{{
                renderKey(title.key)
              }}</label>
            </b-col>
            <b-col sm="9">
              <b-form-input
                :disabled="!isEditing"
                :id="title.key"
                v-model="title.value"
              />
            </b-col>
          </div>
        </div>
        <!-- Share title -->
        <div class="col-lg-12 mb-3">
          <div class="row">
            <b-col sm="3">
              <label class="label-content" :for="`${shareTitle.key}`">{{
                renderKey(shareTitle.key)
              }}</label>
            </b-col>
            <b-col sm="9">
              <b-form-input
                :disabled="!isEditing"
                :id="shareTitle.key"
                v-model="shareTitle.value"
              />
            </b-col>
          </div>
        </div>
        <!-- Share name -->
        <div class="col-lg-12 mb-3">
          <div class="row">
            <b-col sm="3">
              <label class="label-content" :for="`${shareName.key}`">{{
                renderKey(shareName.key)
              }}</label>
            </b-col>
            <b-col sm="9">
              <b-form-input
                :disabled="!isEditing"
                :id="shareName.key"
                v-model="shareName.value"
              />
            </b-col>
          </div>
        </div>
        <!-- Share phone -->
        <div class="col-lg-12 mb-3">
          <div class="row">
            <b-col sm="3">
              <label class="label-content" :for="`${sharePhone.key}`">{{
                renderKey(sharePhone.key)
              }}</label>
            </b-col>
            <b-col sm="9">
              <b-form-input
                :disabled="!isEditing"
                :id="sharePhone.key"
                v-model="sharePhone.value"
              />
            </b-col>
          </div>
        </div>
        <!-- Share email -->
        <div class="col-lg-12 mb-3">
          <div class="row">
            <b-col sm="3">
              <label class="label-content" :for="`${shareEmail.key}`">{{
                renderKey(shareEmail.key)
              }}</label>
            </b-col>
            <b-col sm="9">
              <b-form-input
                :disabled="!isEditing"
                :id="shareEmail.key"
                v-model="shareEmail.value"
              ></b-form-input>
            </b-col>
          </div>
        </div>
        <!-- Share message -->
        <div class="col-lg-12 mb-3">
          <div class="row">
            <b-col sm="3">
              <label class="label-content" :for="`${shareMessage.key}`">{{
                renderKey(shareMessage.key)
              }}</label>
            </b-col>
            <b-col sm="9">
              <b-form-input
                :id="shareMessage.key"
                v-model="shareMessage.value"
                :disabled="!isEditing"
              ></b-form-input>
            </b-col>
          </div>
        </div>
        <!-- Share button -->
        <div class="col-lg-12 mb-3">
          <div class="row">
            <b-col sm="3">
              <label class="label-content" :for="`${shareButton.key}`">{{
                renderKey(shareButton.key)
              }}</label>
            </b-col>
            <b-col sm="9">
              <b-form-input
                :disabled="!isEditing"
                :id="shareButton.key"
                v-model="shareButton.value"
              ></b-form-input>
            </b-col>
          </div>
        </div>
        <!-- Share error -->
        <div class="col-lg-12 mb-3">
          <div class="row">
            <b-col sm="3">
              <label class="label-content" :for="`${shareError.key}`">{{
                renderKey(shareError.key)
              }}</label>
            </b-col>
            <b-col sm="9">
              <b-form-input
                :id="shareError.key"
                v-model="shareError.value"
                :disabled="!isEditing"
              ></b-form-input>
            </b-col>
          </div>
        </div>
        <!-- Kusto home address -->
        <div class="col-lg-12 mb-3">
          <div class="row">
            <b-col sm="3">
              <label class="label-content" :for="`${kustoAddress.key}`">{{
                renderKey(kustoAddress.key)
              }}</label>
            </b-col>
            <b-col sm="9">
              <b-form-input
                :id="kustoAddress.key"
                v-model="kustoAddress.value"
                :disabled="!isEditing"
              ></b-form-input>
            </b-col>
          </div>
        </div>
        <!-- Copy right -->
        <div class="col-lg-12 mb-3">
          <div class="row">
            <b-col sm="3">
              <label class="label-content" :for="`${copyright.key}`">{{
                renderKey(copyright.key)
              }}</label>
            </b-col>
            <b-col sm="9">
              <b-form-input
                :id="copyright.key"
                v-model="copyright.value"
                :disabled="!isEditing"
              ></b-form-input>
            </b-col>
          </div>
        </div>
      </div>
      <div class="row d-flex justify-content-center" v-if="isEditing">
        <b-button
          class="btn btn-primary save-button mr-2"
          squared
          @click="onSave"
        >
          Save
        </b-button>
        <b-button
          squared
          @click="onCancel"
          class="btn btn-primary cancel-button"
        >
          Cancel
        </b-button>
      </div>
    </b-collapse>
    <!-- Title -->
  </div>
</template>

<script>
import { translationKeys, translationType } from "@/constant/index.js";
import { mapActions } from "vuex";

export default {
  props: {
    contacts: {
      type: Array,
      default: () => [],
    },
    language: {
      type: String,
      default: "vn",
    },
  },
  data() {
    return {
      title: {},
      shareTitle: {},
      shareName: {},
      sharePhone: {},
      shareEmail: {},
      shareMessage: {},
      shareButton: {},
      shareError: {},
      copyright: {},
      kustoAddress: {},
      contactKeys: translationKeys.contact,
      isEditing: false,
    };
  },
  watch: {
    contacts: {
      handler(val) {
        if (val && val.length) {
          this.convertData(val);
        }
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions("translations", ["updateTranslation"]),
    ...mapActions("excel", ["downloadSubmission"]),
    renderKey(key) {
      let res = "";
      if (key) {
        res = key.replace(/_/g, " ");
        res = res.charAt(0).toUpperCase() + res.slice(1);
      }
      return res;
    },
    onEdit() {
      this.isEditing = true;
    },
    async onSave() {
      try {
        const payload = {
          contact_title: this.title.value,
          contact_share_title: this.shareTitle.value,
          contact_share_name: this.shareName.value,
          contact_share_phone: this.sharePhone.value,
          contact_share_email: this.shareEmail.value,
          contact_share_message: this.shareMessage.value,
          contact_share_button: this.shareButton.value,
          contact_share_error_message: this.shareError.value,
          copy_right: this.copyright.value,
          kusto_address: this.kustoAddress.value,
        };

        await this.updateTranslation({
          payload,
          language: this.language,
          type: translationType.contact,
        });
        this.isEditing = false;
      } catch (err) {}
    },
    onCancel() {
      this.isEditing = false;
      this.convertData(this.contacts);
    },
    convertData(val) {
      this.title = val.find((i) => i.key == this.contactKeys.contact_title);
      this.shareTitle = val.find(
        (i) => i.key == this.contactKeys.contact_share_title
      );
      this.shareName = val.find(
        (i) => i.key == this.contactKeys.contact_share_name
      );
      this.shareEmail = val.find(
        (i) => i.key == this.contactKeys.contact_share_email
      );
      this.shareMessage = val.find(
        (i) => i.key == this.contactKeys.contact_share_message
      );
      this.sharePhone = val.find(
        (i) => i.key == this.contactKeys.contact_share_phone
      );
      this.shareButton = val.find(
        (i) => i.key == this.contactKeys.contact_share_button
      );
      this.shareError = val.find(
        (i) => i.key == this.contactKeys.contact_share_error_message
      );
      this.copyright = val.find((i) => i.key == this.contactKeys.copy_right);
      this.kustoAddress = val.find(
        (i) => i.key == this.contactKeys.kusto_address
      );
    },
    async onDownload() {
      try {
        window.location.href = "https://api.kustohome.com.vn/excel/download";
        // const res = await this.downloadSubmission();
        // var fileURL = window.URL.createObjectURL(
        //   new Blob([res], { type: "text/xlsx" })
        // );
        // var fileLink = document.createElement("a");
        // fileLink.href = fileURL;
        // fileLink.setAttribute("download", "report.xlsx");
        // document.body.appendChild(fileLink);
        // fileLink.click();
      } catch (err) {}
    },
  },
};
</script>
<style lang="scss" scoped>
#contact-translation {
  margin-bottom: 25px !important;
  margin-top: 0px !important;
}
.edit-button,
.save-button {
  border: none !important;
  margin-left: 15px;
  margin-bottom: 15px;
  background-color: #00aeef !important;
}
.cancel-button {
  margin-left: 15px;
  margin-bottom: 15px;
}
.label-content {
  word-break: break-word !important;
}
</style>