<template>
  <div>
    <AdminNavbar @change-translate="onChangeTranslate"></AdminNavbar>
    <Header></Header>
    <Introduce></Introduce>
    <Discussion></Discussion>
    <Topic1Image></Topic1Image>
    <Topic2Image></Topic2Image>
    <Topic3Image></Topic3Image>
    <IntroduceYoutube></IntroduceYoutube>
    <TalkShowYoutube></TalkShowYoutube>
    <TopicYoutube1></TopicYoutube1>
    <TopicYoutube2></TopicYoutube2>
    <TopicYoutube3></TopicYoutube3>
    <Translation ref="translation"></Translation>
    <div class="footer-alt py-3">
      <div class="horizol d-flex justify-content-center"></div>
      <div
        class="
          container-footer
          d-flex
          justify-content-center justify-content-center
        "
      >
        <p class="font-size-15 mb-0">2022 Kusto Home. All Rights Reserved.</p>
      </div>
    </div>
  </div>
</template>
<script>
import AdminNavbar from "@/components/admin/AdminNavbar";
import Header from "@/components/admin/Header";
import Introduce from "@/components/admin/Introduce";
import Discussion from "@/components/admin/Discussion";
import Translation from "@/components/admin/Translation";
import TalkShowYoutube from "@/components/admin/TalkShowYoutube";
import IntroduceYoutube from "@/components/admin/IntroduceYoutube";
import TopicYoutube1 from "@/components/admin/TopicYoutube1";
import TopicYoutube2 from "@/components/admin/TopicYoutube2";
import TopicYoutube3 from "@/components/admin/TopicYoutube3";

import Topic1Image from "@/components/admin/Topic1Image";
import Topic2Image from "@/components/admin/Topic2Image";
import Topic3Image from "@/components/admin/Topic3Image";

export default {
  components: {
    AdminNavbar,
    Header,
    Introduce,
    Translation,
    Discussion,
    TalkShowYoutube,
    TopicYoutube1,
    Topic1Image,
    Topic2Image,
    TopicYoutube2,
    IntroduceYoutube,
    Topic3Image,
    TopicYoutube3,
  },
  mounted() {
    const authenticate = sessionStorage.getItem("authenticate");
    if (!authenticate) {
      this.$router.push("/login");
    }
  },
  methods: {
    async onChangeTranslate(payload) {
      if (payload) {
        this.$refs.translation.currentLang = "en";
        await this.$refs.translation.getTranslation();
      } else {
        this.$refs.translation.currentLang = "vn";
        await this.$refs.translation.getTranslation();
      }
    },
  },
};
</script>
<style lang="scss">
.container-footer {
  p {
    color: #fff;
  }
}
</style>
