<template>
  <div class="card" id="introduce-translation">
    <b-card-header header-tag="nav">
      <b-nav card-header tabs>
        <b-nav-item active v-b-toggle.introTranslation variant="primary">
          Introduction Section
        </b-nav-item>
      </b-nav>
    </b-card-header>
    <b-collapse id="introTranslation" visible class="mt-2 show mb-4 ml-4 mr-4">
      <div class="row mt-3">
        <button
          class="btn btn-primary edit-button"
          squared
          v-if="!isEditing"
          @click="onEdit"
        >
          Edit
        </button>
      </div>
      <!-- Title -->
      <div class="row mb-3">
        <b-col sm="3">
          <label class="label-content" :for="`${introductionTitle.key}`">{{
            renderKey(introductionTitle.key)
          }}</label>
        </b-col>
        <b-col sm="9">
          <b-form-input
            :disabled="!isEditing"
            :id="introductionTitle.key"
            v-model="introductionTitle.value"
          ></b-form-input>
        </b-col>
      </div>
      <!-- Subtitle -->
      <div class="row mb-3">
        <b-col sm="3">
          <label class="label-content" :for="`${introductionSubtitle.key}`">{{
            renderKey(introductionSubtitle.key)
          }}</label>
        </b-col>
        <b-col sm="9">
          <b-form-input
            :disabled="!isEditing"
            :id="introductionSubtitle.key"
            v-model="introductionSubtitle.value"
          ></b-form-input>
        </b-col>
      </div>
      <!-- Content -->
      <div class="row mb-3">
        <b-col sm="3">
          <label class="label-content" :for="`${introductionContent.key}`">{{
            renderKey(introductionContent.key)
          }}</label>
        </b-col>
        <b-col sm="9">
          <b-form-textarea
            :disabled="!isEditing"
            :id="introductionContent.key"
            v-model="introductionContent.value"
            rows="10"
          ></b-form-textarea>
        </b-col>
      </div>
      <!-- Criterial -->
      <div class="row">
        <template v-for="(item, index) of introductionCriteria">
          <div class="col-lg-12 mb-3" :key="index">
            <div class="row">
              <b-col sm="3">
                <label
                  :for="`${introductionContent.key}`"
                  class="label-content"
                  >{{ renderKey(item.key) }}</label
                >
              </b-col>
              <b-col sm="9">
                <b-form-input
                  :id="introductionContent.key"
                  v-model="item.value"
                  :disabled="!isEditing"
                ></b-form-input>
              </b-col>
            </div>
          </div>
        </template>
      </div>
      <div class="row d-flex justify-content-center" v-if="isEditing">
        <b-button
          class="btn btn-primary save-button mr-2"
          squared
          @click="onSave"
        >
          Save
        </b-button>
        <b-button
          squared
          @click="onCancel"
          class="btn btn-primary cancel-button"
        >
          Cancel
        </b-button>
      </div>
    </b-collapse>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { translationKeys, translationType } from "@/constant/index.js";

export default {
  props: {
    introductions: {
      type: Array,
      default: () => [],
    },
    language: {
      type: String,
      default: "vn",
    },
  },
  data() {
    return {
      introductionTitle: {},
      introductionSubtitle: {},
      introductionContent: {},
      introductionCriteria: [],
      introKeys: translationKeys.introductions,
      listKey: [
        translationKeys.introductions.introduction_title,
        translationKeys.introductions.introduction_subtitle,
        translationKeys.introductions.introduction_content,
      ],
      isEditing: false,
    };
  },
  watch: {
    introductions: {
      handler(val) {
        if (val && val.length) {
          this.convertData(val);
        }
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions("translations", ["updateTranslation"]),
    renderKey(key) {
      let res = "";
      if (key) {
        res = key.replace(/_/g, " ");
        res = res.charAt(0).toUpperCase() + res.slice(1);
      }
      return res;
    },
    onEdit() {
      this.isEditing = true;
    },
    onCancel() {
      this.isEditing = false;
      this.convertData(this.introductions);
    },
    async onSave() {
      try {
        this.isEditing = false;
        let payload = {
          introduction_title: this.introductionTitle.value,
          introduction_subtitle: this.introductionSubtitle.value,
          introduction_content: this.introductionContent.value,
        };
        const args = {};
        for (const item of this.introductionCriteria) {
          args[item.key] = item.value;
        }
        payload = { ...payload, ...args };

        await this.updateTranslation({
          payload,
          language: this.language,
          type: translationType.introduction,
        });
      } catch (err) {}
    },
    convertData(val) {
      this.introductionTitle = val.find(
        (i) => i.key == this.introKeys.introduction_title
      );
      this.introductionSubtitle = val.find(
        (i) => i.key == this.introKeys.introduction_subtitle
      );
      this.introductionContent = val.find(
        (i) => i.key == this.introKeys.introduction_content
      );
      this.introductionCriteria = val.filter(
        (i) => !this.listKey.includes(i.key)
      );
    },
  },
};
</script>
<style lang="scss" scoped>
#introduce-translation {
  margin-bottom: 25px !important;
  margin-top: 0px !important;
}
.label-content {
  word-break: break-word !important;
}

.edit-button,
.save-button {
  border: none !important;
  margin-left: 15px;
  margin-bottom: 15px;
  background-color: #00aeef !important;
}
.cancel-button {
  margin-left: 15px;
  margin-bottom: 15px;
}
.edit-button:hover,
.save-button:hover {
  background-color: #00aeef !important;
}
</style>