<template>
  <div class="card" id="topic-translation-3">
    <b-card-header header-tag="nav">
      <b-nav card-header tabs>
        <b-nav-item active v-b-toggle.topic3Translation variant="primary">
          Topic Section 3
        </b-nav-item>
      </b-nav>
    </b-card-header>
    <b-collapse id="topic3Translation" visible class="mt-2 show mb-4 ml-4 mr-4">
      <div class="row mt-3">
        <button
          class="btn btn-primary edit-button"
          squared
          v-if="!isEditing"
          @click="onEdit"
        >
          Edit
        </button>
      </div>
      <!-- header -->
      <div class="row mb-3">
        <b-col sm="3">
          <label class="label-content" :for="`${header.key}`">{{
            renderKey(header.key)
          }}</label>
        </b-col>
        <b-col sm="9">
          <b-form-input
            :disabled="!isEditing"
            :id="header.key"
            v-model="header.value"
          ></b-form-input>
        </b-col>
      </div>
      <!-- title -->
      <div class="row mb-3">
        <b-col sm="3">
          <label class="label-content" :for="`${title.key}`">{{
            renderKey(title.key)
          }}</label>
        </b-col>
        <b-col sm="9">
          <b-form-input
            :disabled="!isEditing"
            :id="title.key"
            v-model="title.value"
          ></b-form-input>
        </b-col>
      </div>
      <!-- subtitle -->
      <div class="row mb-3">
        <b-col sm="3">
          <label class="label-content" :for="`${subtitle.key}`">{{
            renderKey(subtitle.key)
          }}</label>
        </b-col>
        <b-col sm="9">
          <b-form-input
            :disabled="!isEditing"
            :id="subtitle.key"
            v-model="subtitle.value"
          ></b-form-input>
        </b-col>
      </div>
      <!-- talkShow -->
      <div class="row mb-3">
        <b-col sm="3">
          <label class="label-content" :for="`${fullTalkShow.key}`">{{
            renderKey(fullTalkShow.key)
          }}</label>
        </b-col>
        <b-col sm="9">
          <b-form-input
            :disabled="!isEditing"
            :id="fullTalkShow.key"
            v-model="fullTalkShow.value"
          ></b-form-input>
        </b-col>
      </div>
      <!-- customer -->
      <div class="row mb-3">
        <b-col sm="3">
          <label class="label-content" :for="`${customer.key}`">{{
            renderKey(customer.key)
          }}</label>
        </b-col>
        <b-col sm="9">
          <b-form-input
            :disabled="!isEditing"
            :id="customer.key"
            v-model="customer.value"
          ></b-form-input>
        </b-col>
      </div>
      <!-- customerName -->
      <div class="row mb-3">
        <b-col sm="3">
          <label class="label-content" :for="`${customerName.key}`">{{
            renderKey(customerName.key)
          }}</label>
        </b-col>
        <b-col sm="9">
          <b-form-input
            :disabled="!isEditing"
            :id="customerName.key"
            v-model="customerName.value"
          ></b-form-input>
        </b-col>
      </div>
      <!-- watchNow -->
      <div class="row mb-3">
        <b-col sm="3">
          <label class="label-content" :for="`${watchNow.key}`">{{
            renderKey(watchNow.key)
          }}</label>
        </b-col>
        <b-col sm="9">
          <b-form-input
            :disabled="!isEditing"
            :id="watchNow.key"
            v-model="watchNow.value"
          ></b-form-input>
        </b-col>
      </div>
      <!-- shortTalkshow -->
      <div class="row mb-3">
        <b-col sm="3">
          <label class="label-content" :for="`${shortTalkshow.key}`">{{
            renderKey(shortTalkshow.key)
          }}</label>
        </b-col>
        <b-col sm="9">
          <b-form-input
            :disabled="!isEditing"
            :id="shortTalkshow.key"
            v-model="shortTalkshow.value"
          ></b-form-input>
        </b-col>
      </div>
      <!-- shortTalkshowDescription1 -->
      <div class="row mb-3">
        <b-col sm="3">
          <label
            class="label-content"
            :for="`${shortTalkshowDescription1.key}`"
            >{{ renderKey(shortTalkshowDescription1.key) }}</label
          >
        </b-col>
        <b-col sm="9">
          <b-form-input
            :disabled="!isEditing"
            :id="shortTalkshowDescription1.key"
            v-model="shortTalkshowDescription1.value"
          ></b-form-input>
        </b-col>
      </div>
      <!-- shortTalkshowDescription2 -->
      <div class="row mb-3">
        <b-col sm="3">
          <label
            class="label-content"
            :for="`${shortTalkshowDescription2.key}`"
            >{{ renderKey(shortTalkshowDescription2.key) }}</label
          >
        </b-col>
        <b-col sm="9">
          <b-form-input
            :disabled="!isEditing"
            :id="shortTalkshowDescription2.key"
            v-model="shortTalkshowDescription2.value"
          ></b-form-input>
        </b-col>
      </div>
      <!-- shortTalkshowDescription3 -->
      <div class="row mb-3">
        <b-col sm="3">
          <label
            class="label-content"
            :for="`${shortTalkshowDescription3.key}`"
            >{{ renderKey(shortTalkshowDescription3.key) }}</label
          >
        </b-col>
        <b-col sm="9">
          <b-form-input
            :disabled="!isEditing"
            :id="shortTalkshowDescription3.key"
            v-model="shortTalkshowDescription3.value"
          ></b-form-input>
        </b-col>
      </div>
      <!-- shortTalkshowDescription4 -->
      <div class="row mb-3">
        <b-col sm="3">
          <label
            class="label-content"
            :for="`${shortTalkshowDescription4.key}`"
            >{{ renderKey(shortTalkshowDescription4.key) }}</label
          >
        </b-col>
        <b-col sm="9">
          <b-form-input
            :disabled="!isEditing"
            :id="shortTalkshowDescription4.key"
            v-model="shortTalkshowDescription4.value"
          ></b-form-input>
        </b-col>
      </div>
      <!-- shortTalkshowDescription5 -->
      <div class="row mb-3">
        <b-col sm="3">
          <label
            class="label-content"
            :for="`${shortTalkshowDescription5.key}`"
            >{{ renderKey(shortTalkshowDescription5.key) }}</label
          >
        </b-col>
        <b-col sm="9">
          <b-form-input
            :disabled="!isEditing"
            :id="shortTalkshowDescription5.key"
            v-model="shortTalkshowDescription5.value"
          ></b-form-input>
        </b-col>
      </div>
      <div class="row d-flex justify-content-center" v-if="isEditing">
        <b-button
          class="btn btn-primary save-button mr-2"
          squared
          @click="onSave"
        >
          Save
        </b-button>
        <b-button
          squared
          @click="onCancel"
          class="btn btn-primary cancel-button"
        >
          Cancel
        </b-button>
      </div>
    </b-collapse>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { translationKeys, translationType } from "@/constant/index.js";
export default {
  props: {
    topic: {
      type: Array,
      default: () => [],
    },
    language: {
      type: String,
      default: "vn",
    },
  },
  data() {
    return {
      isEditing: false,
      header: "",
      title: "",
      subtitle: "",
      fullTalkShow: "",
      customer: "",
      customerName: "",
      watchNow: "",
      shortTalkshow: "",
      shortTalkshowDescription1: "",
      shortTalkshowDescription2: "",
      shortTalkshowDescription3: "",
      shortTalkshowDescription4: "",
      shortTalkshowDescription5: "",
    };
  },
  watch: {
    topic: {
      handler(val) {
        if (val && val.length) {
          this.convertData(val);
        }
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions("translations", ["updateTranslation"]),
    renderKey(key) {
      let res = "";
      if (key) {
        res = key.replace(/_/g, " ");
        res = res.charAt(0).toUpperCase() + res.slice(1);
      }
      return res;
    },
    onEdit() {
      this.isEditing = true;
    },
    async onSave() {
      try {
        this.isEditing = false;
        const payload = {
          topic_3_header: this.header.value,
          topic_3_title: this.title.value,
          topic_3_subtitle: this.subtitle.value,
          topic_3_full_talkshow: this.fullTalkShow.value,
          topic_3_full_talkshow_customer: this.customer.value,
          topic_3_full_talkshow_customer_name: this.customerName.value,
          topic_3_watch_now: this.watchNow.value,
          topic_3_short_talkshow: this.shortTalkshow.value,
          topic_3_short_talkshow_description_1: this.shortTalkshowDescription1
            .value,
          topic_3_short_talkshow_description_2: this.shortTalkshowDescription2
            .value,
          topic_3_short_talkshow_description_3: this.shortTalkshowDescription3
            .value,
          topic_3_short_talkshow_description_4: this.shortTalkshowDescription4
            .value,
          topic_3_short_talkshow_description_5: this.shortTalkshowDescription5
            .value,
        };
        await this.updateTranslation({
          payload,
          language: this.language,
          type: translationType.topic3,
        });
      } catch (err) {}
    },
    onCancel() {
      this.isEditing = false;
    },
    convertData(val) {
      this.header = val.find(
        (i) => i.key == translationKeys.topic3.topic_3_header
      );
      this.title = val.find(
        (i) => i.key == translationKeys.topic3.topic_3_title
      );
      this.subtitle = val.find(
        (i) => i.key == translationKeys.topic3.topic_3_subtitle
      );

      this.fullTalkShow = val.find(
        (i) => i.key == translationKeys.topic3.topic_3_full_talkshow
      );

      this.customer = val.find(
        (i) => i.key == translationKeys.topic3.topic_3_full_talkshow_customer
      );
      this.customerName = val.find(
        (i) =>
          i.key == translationKeys.topic3.topic_3_full_talkshow_customer_name
      );
      this.watchNow = val.find(
        (i) => i.key == translationKeys.topic3.topic_3_watch_now
      );
      this.shortTalkshow = val.find(
        (i) => i.key == translationKeys.topic3.topic_3_short_talkshow
      );
      this.shortTalkshowDescription1 = val.find(
        (i) =>
          i.key == translationKeys.topic3.topic_3_short_talkshow_description_1
      );
      this.shortTalkshowDescription5 = val.find(
        (i) =>
          i.key == translationKeys.topic3.topic_3_short_talkshow_description_5
      );
      this.shortTalkshowDescription2 = val.find(
        (i) =>
          i.key == translationKeys.topic3.topic_3_short_talkshow_description_2
      );
      this.shortTalkshowDescription3 = val.find(
        (i) =>
          i.key == translationKeys.topic3.topic_3_short_talkshow_description_3
      );
      this.shortTalkshowDescription4 = val.find(
        (i) =>
          i.key == translationKeys.topic3.topic_3_short_talkshow_description_4
      );
    },
  },
};
</script>

<style lang="scss" scoped>
#topic-translation-3 {
  margin-bottom: 25px !important;
  margin-top: 0px !important;
}
.label-content {
  word-break: break-word !important;
}

.edit-button,
.save-button {
  border: none !important;
  margin-left: 15px;
  margin-bottom: 15px;
  background-color: #00aeef !important;
}
.cancel-button {
  margin-left: 15px;
  margin-bottom: 15px;
}
.edit-button:hover,
.save-button:hover {
  background-color: #00aeef !important;
}
</style>
