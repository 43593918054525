<template>
  <!--Navbar Start-->
  <nav
    class="navbar navbar-expand-lg fixed-top navbar-custom sticky sticky-dark"
    id="navbar"
    :class="{ 'navbar-light': navbarcolor === true }"
  >
    <div class="container-fluid">
      <div
        class="d-flex flex-row-reverse flex-lg-row justify-content-center menu"
      >
        <div class="logo-wrapper">
          <a @click="goToHome()" class="pointer">
            <img src="@/assets/images/talkshow_logo.png" alt />
          </a>
        </div>

        <button
          class="navbar-toggler btn-hamburger"
          type="button"
          @click="toggleMenu()"
          aria-label="Toggle navigation"
        >
          <i class="mdi mdi-menu"></i>
        </button>
        <div class="collapse navbar-collapse" id="navbarCollapse">
          <ul
            class="navbar-nav mx-auto navbar-center"
            id="mySidenav"
            v-scroll-spy-active
          >
            <li
              v-for="(item, index) in menu"
              :key="index"
              class="nav-item"
              @click.prevent.stop="item.click()"
              :class="{ 'active-translation': isActiveTranslate(item) }"
            >
              <a :class="item.class">{{ item.title }}</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </nav>
  <!-- Navbar End -->
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
export default {
  props: {
    navbarcolor: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(["isTranslating"]),
    menu() {
      return [
        {
          title: "HOME",
          click: () => {
            this.goToHome();
          },
          class: "nav-link pointer",
        },
        {
          title: "VN",
          click: () => {
            this.onTranslate(false);
          },
          class: "nav-link pointer",
        },
        {
          title: "EN",
          click: () => {
            this.onTranslate(true);
          },
          class: "nav-link pointer",
        },
        {
          title: "LOGOUT",
          click: () => {
            this.onLogout();
          },
          class: "nav-link pointer",
        },
      ];
    },
  },
  mounted: () => {
    // window.onscroll = function () {
    //   onwindowScroll();
    // };
    // var navbar = document.getElementById("navbar");
    // function onwindowScroll() {
    //   if (
    //     document.body.scrollTop > 50 ||
    //     document.documentElement.scrollTop > 50
    //   ) {
    //     navbar.classList.add("nav-sticky");
    //   } else {
    //     navbar.classList.remove("nav-sticky");
    //   }
    // }
  },
  methods: {
    ...mapMutations(["setTranslate"]),
    toggleMenu() {
      document.getElementById("navbarCollapse").classList.toggle("show");
    },
    onTranslate(payload) {
      this.setTranslate(payload);
      this.$emit("change-translate", payload);
    },
    goToHome() {
      this.$router.push("/");
    },
    onLogout() {
      const authenticate = sessionStorage.getItem("authenticate");
      if (authenticate) {
        sessionStorage.removeItem("authenticate");
        this.$router.push("/");
      }
    },
    isActiveTranslate(item) {
      return (
        (item.title == "VN" && !this.isTranslating) ||
        (item.title == "EN" && this.isTranslating)
      );
    },
  },
};
</script>
<style lang="scss" scoped>
#navbar {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 80px;
  background-color: #fff;
  .menu {
    width: 100%;
  }
  .mx-auto {
    margin-right: initial !important;
  }

  .navbar-toggler {
    color: #00aeef !important;
  }

  .logo-wrapper {
    img {
      display: block;
      max-width: 230px;
      max-height: 70px;
      width: auto;
      height: auto;
    }
  }

  .navbar-nav {
    li {
      text-transform: uppercase;

      a {
        font-weight: bold;
        font-size: 16px;
        color: #11223e !important;
      }

      a:hover {
        color: #00aeef !important;
      }
    }
    li.active-translation {
      background-color: #00aeef !important;
      border-radius: 4px;

      a {
        color: #fff !important;
      }
    }

    li.translated {
      border-radius: 4px;

      background-color: #00aeef;
      a {
        margin-left: 10px;
        margin-right: 10px;
        color: #fff !important;
      }
    }

    li.active {
      a {
        color: #00aeef;
      }
    }
  }
}

@media only screen and (max-width: 992px) {
  #navbar {
    padding-top: 10px;
    padding-bottom: 10px;
    .logo-wrapper {
      img {
        max-width: 230px;
        max-height: 60px;
      }
    }
    .navbar-nav {
      width: 100%;
      position: absolute;
      text-align: center;
      li {
        background-color: #fff;
        a {
          margin-left: 0px !important;
          margin-right: 0px !important;
        }
      }
    }
    .btn-hamburger {
      position: absolute;
      left: 5%;
      top: 35%;
      z-index: 1000;
    }

    #navbarCollapse {
      position: absolute;
      left: 0px;
      right: 0px;
      bottom: 0px;
    }
  }
}

@media only screen and (max-width: 768px) {
  #navbar {
    .navbarCollapse {
      padding: 0px;
    }
    .navbar-nav {
      li {
        a {
          font-size: 15px;
        }
      }
    }
  }
}
</style>
