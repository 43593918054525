<template>
  <section class="section" id="admin-header">
    <div class="container-fluid">
      <div class="card">
        <b-card-header header-tag="nav">
          <b-nav card-header tabs>
            <b-nav-item active v-b-toggle.headerTranslation variant="primary">
              Logo and Sliders
            </b-nav-item>
          </b-nav>
        </b-card-header>
        <b-collapse
          id="headerTranslation"
          visible
          class="mt-2 show mb-4 ml-4 mr-4"
        >
          <div class="mt-4 mb-4 ml-4 mr-4">
            <div class="row header-logo">
              <div class="col-xl-6 col-lg-6">
                <div class="row mb-3">
                  <div class="col-xl-3 col-lg-6 d-flex align-items-center">
                    <span>Top logo</span>
                  </div>
                  <div class="col-xl-6 col-lg-6 img-logo d-flex flex-column">
                    <div class="blog-box blog-box mb-2 mr-4">
                      <img
                        :src="convertImage(imgLogo)"
                        alt
                        class="
                          img-fluid
                          d-block
                          mx-auto
                          rounded
                          img-header-logo
                        "
                      />
                    </div>
                    <div class="d-flex justify-content-center">
                      <template v-if="isEditing">
                        <input
                          ref="logo"
                          type="file"
                          accept="image/*"
                          class="d-none"
                          @change="imageChange"
                        />

                        <button
                          class="btn btn-secondary mt-1 mr-2"
                          @click.prevent.stop="chooseImage"
                        >
                          Choose
                        </button>
                      </template>
                      <template v-else>
                        <button
                          class="btn btn-primary mt-1 mr-2"
                          @click="onEdit"
                        >
                          Edit
                        </button>
                        <button
                          class="btn btn-danger mt-1 mr-2"
                          @click="deleteLogo"
                        >
                          Delete
                        </button>
                      </template>

                      <template v-if="isEditing">
                        <button
                          class="btn btn-primary mt-1 mr-2"
                          @click="onSave"
                        >
                          Save
                        </button>
                        <button
                          class="btn btn-warning mt-1 mr-2"
                          @click="onCancel"
                        >
                          Cancel
                        </button>
                        <button
                          class="btn btn-danger mt-1 mr-2"
                          @click="deleteLogo"
                        >
                          Delete
                        </button>
                      </template>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <template v-for="(item, index) in arrSlider">
                <div class="col-xl-6 col-lg-6" :key="index">
                  <div class="row mb-3">
                    <div class="col-xl-3 col-lg-3 d-flex align-items-center">
                      <span>{{ item.title }}</span>
                    </div>
                    <div class="col-xl-9 col-lg-9 img-logo d-flex flex-column">
                      <div class="blog-box blog-box mb-2 mr-4">
                        <img
                          :src="convertImage(item.imgSrc)"
                          alt
                          class="img-fluid d-block mx-auto rounded"
                        />
                      </div>
                      <div class="d-flex justify-content-center">
                        <template v-if="item.isEditing">
                          <input
                            :ref="item.ref"
                            type="file"
                            accept="image/*"
                            class="d-none"
                            @change="sliderChange($event, item.index)"
                          />
                          <button
                            class="btn btn-secondary mt-1 mr-2"
                            @click.prevent.stop="chooseSliderImage(item.ref)"
                          >
                            Choose
                          </button>
                        </template>
                        <template v-if="!item.isEditing">
                          <button
                            class="btn btn-primary mt-1 mr-2"
                            @click="onEditSlider(item.index)"
                          >
                            Edit
                          </button>
                          <button
                            class="btn btn-danger mt-1"
                            @click="deleteLogoSlider(item)"
                          >
                            Delete
                          </button>
                        </template>
                        <template v-else>
                          <button
                            class="btn btn-primary mt-1 mr-2"
                            @click="onSaveSlider(item)"
                          >
                            Save
                          </button>
                          <button
                            class="btn btn-warning mt-1 mr-2"
                            @click="onCancelChooseSlider(item.index)"
                          >
                            Cancel
                          </button>
                          <button
                            class="btn btn-danger mt-1"
                            @click="deleteLogoSlider(item)"
                          >
                            Delete
                          </button>
                        </template>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </b-collapse>
      </div>
    </div>
  </section>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { filename, location } from "@/constant/index.js";

export default {
  data() {
    return {
      isEditing: false,
      imgLogo: require("@/assets/images/no_image.jpg"),
      arrSlider: [
        {
          index: 0,
          name: filename.slider1,
          title: "Slider 1",
          isEditing: false,
          imgSrc: "",
          ref: "slider1",
        },
        {
          index: 1,
          name: filename.slider2,
          isEditing: false,
          title: "Slider 2",
          imgSrc: "",
          ref: "slider2",
        },
        {
          index: 2,
          name: filename.slider3,
          isEditing: false,
          title: "Slider 3",
          imgSrc: "",
          ref: "slider3",
        },
        {
          index: 3,
          name: filename.slider4,
          isEditing: false,
          title: "Slider 4",
          imgSrc: "",
          ref: "slider4",
        },
        {
          index: 4,
          name: filename.slider5,
          isEditing: false,
          title: "Slider 5",
          imgSrc: "",
          ref: "slider5",
        },
      ],
    };
  },
  computed: {
    ...mapGetters("sliders", ["topLogo", "sliders"]),
  },
  watch: {
    topLogo: {
      handler(val) {
        this.imgLogo = val ? val : require("@/assets/images/no_image.jpg");
      },
      immediate: true,
    },
    sliders: {
      handler(val) {
        if (val && val.length) {
          for (const item of val) {
            const index = this.arrSlider.findIndex(
              (i) => i.name == item.imgKey
            );
            if (index > -1) this.arrSlider[index].imgSrc = item.imgValue;
          }
        }
      },
      immediate: true,
    },
  },
  async mounted() {
    try {
      await this.getTopLogo();
      await this.getSliders({
        arrSlidersName: `${filename.slider1},${filename.slider2},${filename.slider3},${filename.slider4},${filename.slider5}`,
      });
    } catch (err) {}
  },
  methods: {
    ...mapActions("sliders", [
      "getTopLogo",
      "onSaveLogo",
      "getSliders",
      "deleteImage",
    ]),
    convertImage(file) {
      if (file) {
        if (typeof file == "object") {
          return URL.createObjectURL(file);
        }
        if (file.match(/\.(jpeg|jpg|gif|png)$/) !== null) {
          return file;
        }
        return require("@/assets/images/no_image.jpg");
      }

      return "";
    },
    imageChange(e) {
      const file = e?.target?.files[0];
      if (file !== null) {
        this.imgLogo = file;
      }
      e.target.value = "";
    },
    onEdit() {
      this.isEditing = true;
    },
    onCancel() {
      this.isEditing = false;
    },
    async onSave() {
      try {
        this.isEditing = false;
        await this.onSaveLogo({
          file: this.imgLogo,
          name: filename.topLogo,
          location: location.header,
        });
      } catch (err) {}
    },
    chooseImage() {
      this.$refs.logo.click();
    },
    chooseSliderImage(ref) {
      this.$refs[ref][0].click();
    },
    onCancelChooseSlider(index) {
      this.arrSlider[index].isEditing = false;
    },
    onEditSlider(index) {
      this.arrSlider[index].isEditing = true;
    },
    sliderChange(e, index) {
      const file = e?.target?.files[0];
      if (file !== null) {
        this.arrSlider[index].imgSrc = file;
      }
      e.target.value = "";
    },
    async onSaveSlider(item) {
      try {
        const id = this.arrSlider.findIndex((i) => i.index == item.index);
        this.arrSlider[id].isEditing = false;
        await this.onSaveLogo({
          file: item.imgSrc,
          name: item.name,
          location: location.sliders,
        });
      } catch (err) {}
    },
    async deleteLogo() {
      try {
        await this.deleteImage({ imgKey: filename.topLogo });
        this.imgLogo = require("@/assets/images/no_image.jpg");
      } catch (err) {}
    },
    async deleteLogoSlider(item) {
      try {
        await this.deleteImage({ imgKey: item.name });
        const res = {
          ...item,
          imgSrc: require("@/assets/images/no_image.jpg"),
          isEditing: false,
        };
        this.arrSlider.splice(item.index, 1, res);
      } catch (err) {}
    },
  },
};
</script>
<style lang="scss" scoped>
#admin-header {
  padding-bottom: 25px !important;
  padding-top: 125px !important;
  .blog-box {
    img {
      width: 200px;
      height: 200px;
    }
  }
  button {
    min-width: 80px;
    border: none !important;
    height: 30px;
    font-size: 12px !important;
    padding-top: 5px !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  .btn-primary {
    background-color: #00aeef !important;
  }
  .header-logo {
    margin-bottom: 40px;
    .img-header-logo {
      max-width: 200px;
      height: auto !important;
    }
  }
}
</style>
