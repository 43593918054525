<template>
  <section class="section" id="admin-introduce">
    <div class="container-fluid">
      <div class="card">
        <b-card-header header-tag="nav">
          <b-nav card-header tabs>
            <b-nav-item
              active
              v-b-toggle.introImageTranslation
              variant="primary"
            >
              Introduction Images
            </b-nav-item>
          </b-nav>
        </b-card-header>
        <b-collapse
          id="introImageTranslation"
          visible
          class="mt-2 show mb-4 ml-4 mr-4"
        >
          <div class="row">
            <div class="col-xl-6 col-lg-6">
              <div class="row mb-3">
                <div class="col-xl-3 col-lg-3 d-flex align-items-center">
                  <span>Introduction Logo</span>
                </div>
                <div class="col-xl-9 col-lg-9 d-flex flex-column">
                  <div class="blog-box blog-box mb-2 mr-4">
                    <img
                      :src="convertImage(logo)"
                      alt
                      class="img-fluid d-block mx-auto rounded"
                    />
                  </div>
                  <div class="d-flex justify-content-center">
                    <template v-if="isEditing">
                      <input
                        ref="introduce"
                        type="file"
                        accept="image/*"
                        class="d-none"
                        @change="imageChange"
                      />
                      <button
                        class="btn btn-secondary mt-1 mr-2"
                        @click.prevent.stop="chooseImage"
                      >
                        <i class="simple-icon-pencil img-operation" /> Choose
                      </button>
                    </template>
                    <template v-if="!isEditing">
                      <button class="btn btn-primary mt-1 mr-2" @click="onEdit">
                        Edit
                      </button>
                      <button
                        class="btn btn-danger mt-1 mr-2"
                        @click="onDelete"
                      >
                        Delete
                      </button>
                    </template>
                    <template v-else>
                      <button
                        class="btn btn-primary mt-1 mr-2"
                        @click="onSave"
                        :disabled="!logo"
                      >
                        Save
                      </button>
                      <button
                        class="btn btn-warning mt-1 mr-2"
                        @click="onCancel"
                      >
                        Cancel
                      </button>
                      <button
                        class="btn btn-danger mt-1 mr-2"
                        @click="onDelete"
                      >
                        Delete
                      </button>
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-collapse>
      </div>
    </div>
  </section>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { filename, location } from "@/constant/index.js";

export default {
  data() {
    return {
      isEditing: false,
      logo: require("@/assets/images/no_image.jpg"),
    };
  },
  computed: {
    ...mapGetters("sliders", ["introduceLogo"]),
  },
  watch: {
    introduceLogo: {
      handler(val) {
        this.logo = val ? val : require("@/assets/images/no_image.jpg");
      },
      immediate: true,
    },
  },
  async mounted() {
    await this.getIntroduceLogo();
  },
  methods: {
    ...mapActions("sliders", ["getIntroduceLogo", "onSaveLogo", "deleteImage"]),
    convertImage(file) {
      if (file) {
        if (typeof file == "object") {
          return URL.createObjectURL(file);
        }
        if (file.match(/\.(jpeg|jpg|gif|png)$/) !== null) {
          return file;
        }

        return require("@/assets/images/no_image.jpg");
      }
      return "";
    },
    imageChange(e) {
      const file = e?.target?.files[0];
      if (file !== null) {
        this.logo = file;
      }
      e.target.value = "";
    },
    chooseImage() {
      this.$refs.introduce.click();
    },
    onEdit() {
      this.isEditing = true;
    },
    onCancel() {
      this.isEditing = false;
    },
    async onSave() {
      try {
        await this.onSaveLogo({
          file: this.logo,
          name: filename.introduceLogo,
          location: location.introduce,
        });
        this.isEditing = false;
      } catch (err) {}
    },
    async onDelete() {
      try {
        await this.deleteImage({ imgKey: filename.introduceLogo });
        this.logo = require("@/assets/images/no_image.jpg");
      } catch (err) {}
    },
  },
};
</script>
<style lang="scss" >
#admin-introduce {
  padding-bottom: 25px !important;
  padding-top: 0px !important;

  .blog-box {
    img {
      width: 200px;
      height: 200px;
    }
  }
  button {
    border: none !important;
    height: 30px;
    font-size: 12px !important;
    padding-top: 5px !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  .btn-primary {
    background-color: #00aeef !important;
  }
}
</style>
